import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';
import classNames from 'classnames';
import { KeyboardEvent, useCallback, useRef, useState } from 'react';
import { Bold as BoldIcon, ChevronDown, Italic as ItalicIcon, Link as LinkIcon, List, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../UI/Button';
import { Checkbox } from '../UI/Checkbox';
import CheckboxLabel from '../UI/CheckboxLabel';
import DropdownMenu from '../UI/DropdownMenu';
import { Icon } from '../UI/Icon';
import { Input } from '../UI/Input';
import useOnClickOutside from '../useOnClickOutside';
import { ClassName } from './ClassName';
import style from './Editor.module.css';
import { OrderdList } from './Icons';
import { VariableHighlighter } from './VariableHighlighter';

interface EditorProps {
  value?: string | null;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  allowProjectColor?: boolean;
  variables?: string[] | readonly string[];
  hideToolbar?: boolean;
}

export function Editor({
  value,
  onChange,
  onBlur,
  allowProjectColor,
  variables,
  hideToolbar = false,
}: EditorProps) {
  const { t } = useTranslation();

  const editorClasses = classNames(
    'px-3 pb-3 text-base border rounded-md border-slate-250 shadow-2xs focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 min-h-[178px]',
    hideToolbar ? 'pt-3' : 'pt-[61px]',
  );

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: editorClasses,
      },
    },
    extensions: [
      Document,
      History,
      Paragraph,
      Text,
      Heading.configure({
        levels: [1],
      }),
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          rel: 'noopener noreferrer',
          target: '_blank',
        },
      }),
      Bold,
      Italic,
      ...allowProjectColor && [ClassName.configure({
        allowedClassNames: ['text-project'],
      })] || [],
      TextStyle,
      OrderedList,
      BulletList,
      ListItem,
      VariableHighlighter.configure({
        variables,
      }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      onChange?.(editor.getHTML());
    },
    onBlur: () => {
      onBlur?.();
    },
  });

  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);

  const [focusedLink, setFocusedLink] = useState({
    href: '',
    className: null,
  });

  const openLinkModal = useCallback(() => {
    if (editor) {
      setFocusedLink({
        href: editor.getAttributes('link').href,
        className: editor.getAttributes('link').class,
      });
      setLinkModalIsOpen(true);
    }
  }, [editor]);

  const closeLinkModal = useCallback(() => {
    setFocusedLink({
      href: '',
      className: null,
    });
    setLinkModalIsOpen(false);
  }, []);

  const toggleLinkModal = useCallback(() => {
    if (!linkModalIsOpen) {
      openLinkModal();
    } else {
      closeLinkModal();
    }
  }, [linkModalIsOpen, openLinkModal, closeLinkModal]);

  const saveLink = useCallback((href: string, className?: string | null) => {
    if (editor) {
      if (href) {
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href, class: className })
          .run();
      } else {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
      }

      closeLinkModal();
    }
  }, [editor, closeLinkModal]);

  const removeLink = useCallback(() => {
    editor?.chain().focus().extendMarkRange('link').unsetLink().run();
    closeLinkModal();
  }, [editor, closeLinkModal]);

  const toggleHeading = useCallback(() => {
    editor?.chain().focus().toggleHeading({ level: 1 }).run();
  }, [editor]);

  const toggleBold = useCallback(() => {
    editor?.chain().focus().toggleBold().run();
  }, [editor]);

  const toggleItalic = useCallback(() => {
    editor?.chain().focus().toggleItalic().run();
  }, [editor]);

  const toggleProjectColor = useCallback(() => {
    editor?.chain().focus().toggleClassName('text-project').run();
  }, [editor]);

  const toggleBulletList = useCallback(() => {
    editor?.chain().focus().toggleBulletList().run();
  }, [editor]);

  const toggleOrderedList = useCallback(() => {
    editor?.chain().focus().toggleOrderedList().run();
  }, [editor]);

  const activeVariables = variables?.filter((variable) => (new RegExp(`{{ *${variable} *}}`)).test(value || ''));

  return (
    <div>
      <div className="relative min-h-[178px]">
        {!hideToolbar && (
          <div className="absolute z-10 rounded-t-[5px] px-[11px] py-2 top-[1px] left-[1px] right-[1px] border-b flex justify-between">
            <div className="space-x-1 flex">
              <Button
                variant="tertiary"
                size="sm"
                className={classNames('!px-2 !text-slate-800', editor?.isActive('heading') ? '!bg-slate-150' : 'bg-white')}
                onClick={toggleHeading}
              >
                {t('shared:editor.heading')}
              </Button>
              <Button
                variant="tertiary"
                size="sm"
                iconButton
                className={classNames(editor?.isActive('bold') ? '!bg-slate-150' : 'bg-white')}
                onClick={toggleBold}
                title={t('shared:editor.bold')}
              >
                <Icon>
                  <BoldIcon strokeWidth={3} />
                </Icon>
              </Button>
              <Button
                variant="tertiary"
                size="sm"
                iconButton
                className={classNames(editor?.isActive('italic') ? '!bg-slate-150' : 'bg-white')}
                onClick={toggleItalic}
                title={t('shared:editor.italic')}
              >
                <Icon>
                  <ItalicIcon />
                </Icon>
              </Button>
              {allowProjectColor && (
                <Button
                  variant="tertiary"
                  size="sm"
                  iconButton
                  className={classNames(editor?.isActive('className', { class: 'text-project' }) ? '!bg-slate-150' : 'bg-white')}
                  onClick={toggleProjectColor}
                  title={t('shared:editor.text_color')}
                >
                  <Icon>
                    <div className="w-4 h-4 rounded bg-project" />
                  </Icon>
                </Button>
              )}
              <Button
                variant="tertiary"
                size="sm"
                iconButton
                className={classNames(editor?.isActive('bulletList') ? '!bg-slate-150' : 'bg-white')}
                onClick={toggleBulletList}
                title={t('shared:editor.bullet_list')}
              >
                <Icon>
                  <List />
                </Icon>
              </Button>
              <Button
                variant="tertiary"
                size="sm"
                iconButton
                className={classNames(editor?.isActive('orderedList') ? '!bg-slate-150' : 'bg-white')}
                onClick={toggleOrderedList}
                title={t('shared:editor.ordered_list')}
              >
                <Icon>
                  <OrderdList />
                </Icon>
              </Button>
              <Button
                variant="tertiary"
                size="sm"
                iconButton
                className={classNames(editor?.isActive('link') ? '!bg-slate-150' : 'bg-white')}
                onClick={toggleLinkModal}
                title={t('shared:editor.link')}
              >
                <Icon>
                  <LinkIcon />
                </Icon>
              </Button>
            </div>
            {variables && variables.length > 0 && (
              <DropdownMenu
                trigger={({ onClick, isOpen }) => (
                  <Button
                    onClick={onClick}
                    size="sm"
                    variant="tertiary"
                    className={classNames('!px-2 ml-auto', isOpen && '!bg-slate-200 !border-slate-200')}
                    title={t('backend:options')}
                  >
                    {t('shared:editor.variables')}
                    <Icon>
                      <ChevronDown />
                    </Icon>
                  </Button>
                )}
                className="!top-8"
                align="end"
              >
                {variables.map((variable) => (
                  <DropdownMenu.Item key={variable}>
                    <a
                      role="button"
                      className={classNames(
                        'font-mono text-xs',
                        activeVariables?.includes(variable) && '!text-emerald-700 !bg-emerald-50 '
                      )}
                      onClick={() => {
                        editor?.commands.insertContent(`{{${variable}}}`);
                        editor?.commands.focus();
                      }}
                    >
                      {variable}
                    </a>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu>
            )}
          </div>
        )}

        {editor && (
          <BubbleMenu
            className="px-2 py-1 bg-white border rounded-md shadow-sm"
            tippyOptions={{ duration: 50, zIndex: 10 }}
            editor={editor}
            shouldShow={({ editor, from, to }) => {
              // Only show the bubble menu for links.
              return from === to && editor.isActive('link');
            }}
          >
            <Button variant="tertiary" size="xs" onClick={openLinkModal}>
              {t('shared:editor.edit')}
            </Button>
            <Button variant="tertiary" size="xs" onClick={removeLink}>
              {t('shared:editor.remove')}
            </Button>
          </BubbleMenu>
        )}

        {linkModalIsOpen && (
          <LinkModal
            href={focusedLink.href}
            className={focusedLink.className}
            onSave={saveLink}
            onCancel={closeLinkModal}
          />
        )}

        {editor && <EditorContent editor={editor} className={classNames(style.editorContent, 'z-1')} />}

        {!editor && ( // Show a preview while the editor is initializing
          <div className={classNames(style.editorContent, 'z-1')}>
            <div className={editorClasses} dangerouslySetInnerHTML={{ __html: value || '' }} />
          </div>
        )}
      </div>
    </div>
  );
}

function LinkModal({
  href: initialHref = '',
  className: initialClassName,
  onSave,
  onCancel,
}: {
  href: string;
  className?: string | null;
  onSave: (url: string, clasName?: string | null) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const { register, watch } = useForm({
    defaultValues: {
      href: initialHref,
      className: initialClassName,
    },
  });

  const href = watch('href');
  const className = watch('className') || null;

  const valid = !href || href.toLowerCase().startsWith('https://') || href.toLowerCase().startsWith('http://');

  const handleSave = useCallback(() => onSave(href.trim(), className), [onSave, href, className]);

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (valid) {
        handleSave();
      }
    }
  }, [valid, handleSave]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(containerRef, () => {
    onCancel();
  });

  return (
    <div
      className="absolute top-[49px] left-[1px] right-[1px] z-[11] bg-slate-100 border-t border-b border-slate-250 px-[11px] py-3 flex space-x-2 items-center"
      ref={containerRef}
    >
      <Input
        {...register('href')}
        autoFocus
        onKeyDown={handleKeyDown}
        placeholder={t('shared:editor.link_placeholder')}
      />
      <CheckboxLabel className="px-1">
        <Checkbox {...register('className')} value="button" />
        <span>
          {t('shared:editor.button')}
        </span>
      </CheckboxLabel>
      <Button onClick={() => handleSave()} disabled={!valid}>
        {t('shared:editor.ok')}
      </Button>
      <Button onClick={() => onCancel()} variant="tertiary" iconButton>
        <Icon >
          <X />
        </Icon>
      </Button>
    </div>
  );
}
